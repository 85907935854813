import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

const BlogPostCard = ({ data, isRecommended }) => {
  const { title, featured_image, category, slug, image_alt_text } = data
  const image = getImage(featured_image)

  return (
    <Link to={isRecommended ? `/tips/${slug}/` : encodeURI(slug)}>
      <div className="flex flex-col max-w-xs cursor-pointer px-6 pb-6">
        <div className="rounded-lg mb-2">
          <GatsbyImage
            image={image}
            layout="fixed"
            width={200}
            height={100}
            className={"rounded-lg"}
            alt={image_alt_text}
          />
        </div>
        <h3 className="mb-0">{category}</h3>
        <h2 className="text-xl font-semibold text-black">{title}</h2>
      </div>
    </Link>
  )
}

export default BlogPostCard
