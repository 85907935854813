import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Container from "../components/container"
import SmallYellowBlob from "../components/small-yellow-blob"

import FinalCTA from "../components/final-cta"
import BlogPostCard from "../components/blog-post-card"
import dayjs from "dayjs"
import PrismicBlogPostCard from "../components/prismic-blog-post-card"
import MasterClassEmailSignup from "../components/master-class-email-signup"
import PuppyEmailSignup from "../components/puppy-email-signup"

const Classes = ({ data, pageContext }) => {
  const { recommended } = pageContext
  const {
    prismicBlogPost: { data: pData },
  } = data
  const { title, category, content, featured_image, posting_date } = pData

  return (
    <Layout>
      <div
        className="border-l-[10px] border-r-[10px] lg:border-l-[60px] lg:border-r-[60px] border-t-[30px] border-b-[30px]  border-lighterPrimary overflow-x-clip"
        style={{ zIndex: 1 }}
      >
        <article className="prose prose-headings:text-lightBlack prose-h3:font-crimson prose-h3:normal-case prose-h3:tracking-normal prose-a:text-primary max-w-none mx-auto prose-img:rounded-lg">
          <Container>
            <div className="relative not-prose">
              <div
                className="absolute top-[0%] lg:top-[5%] left-[0%] lg:left-[10%] w-[100%] lg:w-[80%] h-[100%] lg:h-[110%]"
                style={{ zIndex: 0 }}
              >
                <SmallYellowBlob />
              </div>
              <div
                className="pt-6 lg:pt-24 px-2 lg:px-0 pb-0 max-w-3xl  mx-auto relative"
                style={{ zIndex: 30 }}
              >
                <h1 className="mb-2 lg:mb-4 mt-2 normal-case text-3xl lg:text-5xl tracking-normal font-semibold text-lightBlack">
                  {title.text}
                </h1>
                <hr className="border border-primary max-w-lg mb-2 lg:mb-4" />

                <p className="uppercase font-medium text-sm lg:text-md text-gray-500">
                  <span>{category}</span> | By:{" "}
                  <Link
                    to={/about/}
                    itemProp="author"
                    name={"TAYLOR WYLLIE, KPA CTP"}
                  >
                    <span className={`text-gray-500 hover:underline`}>
                      TAYLOR WYLLIE, KPA CTP
                    </span>
                  </Link>{" "}
                  | <span>{dayjs(posting_date).format("MMM DD, YYYY")}</span>
                </p>
                <div className="rounded-lg mt-4 lg:mt-12 mx-auto text-center">
                  <GatsbyImage
                    image={featured_image.gatsbyImageData}
                    // width={"100%"}
                    // hieght={"150px"}
                    className={"rounded-lg"}
                    alt={featured_image.alt}
                  />
                </div>
              </div>
            </div>
          </Container>
          <Container>
            {category === "Reactivity" ? (
              <MasterClassEmailSignup />
            ) : (
              <PuppyEmailSignup />
            )}
          </Container>

          <Container>
            <div
              className="text-lightBlack mt-8 mb-16 max-w-xl mx-auto relative px-4 xl:px-0"
              style={{ zIndex: 11 }}
            >
              <div
                className="hidden md:block  absolute bottom-[20%] -left-[40%] lg:-left-[85%] w-[100%] rotate-[150deg]	 "
                style={{ zIndex: 0 }}
              >
                <SmallYellowBlob />
              </div>
              <div className="relative">
                <PrismicRichText
                  field={content.richText}
                  components={{
                    embed: ({ node }) => (
                      <div
                        data-oembed={node.oembed.embed_url}
                        data-oembed-type={node.oembed.type}
                        data-oembed-provider={node.oembed.provider_name}
                        dangerouslySetInnerHTML={{
                          __html: node.oembed.html
                            .replace(
                              /width="\d+"/,
                              `class="w-full aspect-video mb-8"`
                            )
                            .replace(/height="\d+"/, ""),
                        }}
                      ></div>
                    ),
                    paragraph: ({ children }) => (
                      <p className="text-gray-600 max-w-xl font-normal">
                        {children}
                      </p>
                    ),
                    hyperlink: ({ node }) => (
                      <>
                        {node.data.type === "Document" ? (
                          <Link to={node.data.url + "/"}>{node.text}</Link>
                        ) : (
                          <Link to={node.data.url}>{node.text}</Link>
                        )}
                      </>
                    ),
                  }}
                />
              </div>
            </div>
          </Container>
          {/* Final CTA */}
        </article>
        <Container>
          <div
            className="flex relative not-prose items-center justify-center mt-4 mb-24 mx-2"
            // style={{ zIndex: 30 }}
          >
            <div className="">
              <StaticImage
                src={"../images/taylor_avatar.png"}
                layout="fixed"
                width={120}
                height={120}
                className={"rounded-lg"}
                alt={`Profile picture of TAYLOR WYLLIE, KPA CTP`}
              />
            </div>
            <div className="flex flex-col ml-6">
              <h3 className="mb-0 te font-medium">Written By</h3>
              <Link to={"/about/"}>
                <h2 className=" text-xl sm:text-3xl mb-0 text-lightBlack">
                  Taylor Wyllie, KPA CTP
                </h2>
              </Link>
              <p className="max-w-sm">
                Certified professional dog trainer and reactive dog owner
              </p>
            </div>
          </div>
        </Container>
        {recommended && (
          <Container>
            <h2 className=" text-xl sm:text-4xl mb-6 text-lightBlack text-center">
              Also worth your time
            </h2>

            <div className="flex not-prose flex-wrap justify-between pt-6 mb-6 max-w-5xl mx-auto">
              {recommended.map(rec => {
                return Object.keys(rec).includes("frontmatter") ? (
                  <BlogPostCard
                    key={rec.slug}
                    data={rec.frontmatter}
                    isRecommended={true}
                  />
                ) : (
                  <PrismicBlogPostCard
                    key={rec.slug}
                    prismicData={{ ...rec.data, url: rec.slug }}
                    isRecommended={true}
                  />
                )
              })}
            </div>
            <div className="text-center mb-24">
              <Link className=" text-center underline text-xl" to={"/tips/"}>
                See All Posts
              </Link>
            </div>
          </Container>
        )}
        <Container>
          {category === "Reactivity" ? (
            <MasterClassEmailSignup />
          ) : (
            <PuppyEmailSignup />
          )}
        </Container>
      </div>
      {/* Final CTA */}
      <FinalCTA />
    </Layout>
  )
}
export const Head = ({ data }) => {
  const { prismicBlogPost } = data
  // console.log("prismicBlogPost", prismicBlogPost)
  const { title, featured_image, posting_date, seo_description, category } =
    prismicBlogPost.data
  return (
    <Seo
      title={title.text}
      description={seo_description.text}
      pathname={`${prismicBlogPost.url}`}
      isBlogPost={true}
      datePublished={posting_date}
      image={featured_image?.publicURL}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          headline: title,
          image: [featured_image?.url],
          datePublished: posting_date,
          dateModified: posting_date,
          author: [
            {
              "@type": "Person",
              name: "Taylor Wyllie",
              honorificSuffix:
                "KPA CTP, Certified Professional Dog Trainer, Owner of Scott's School for Dogs",
              url: "https://scottsschoolfordogs.com/about/",
            },
          ],
        })}
      </script>
    </Seo>
  )
}

export const query = graphql`
  query ($slug: String!) {
    prismicBlogPost(url: { eq: $slug }) {
      id
      href
      url
      uid
      _previewable
      last_publication_date
      data {
        author
        seo_description {
          text
        }
        category
        content {
          richText
        }
        featured_image {
          gatsbyImageData
          alt
          url
        }
        is_active
        posting_date
        tags
        title {
          text
        }
      }
    }
  }
`

export default Classes
