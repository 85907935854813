import axios from "axios"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"

const PuppyEmailSignup = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSignUp = e => {
    e.preventDefault()
    setLoading(true)
    var data = JSON.stringify({
      email,
      name,
      group: "123966651604403646",
    })

    var config = {
      method: "post",
      url: "https://www.scottsschoolfordogs.com/.netlify/functions/subscribe",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response))
        if (response?.status === 200) {
          setSubmitted(true)
          setLoading(false)
        }
      })
      .catch(function (error) {
        console.log(error)
        setLoading(false)
      })
  }

  return (
    <div className="shadow py-12 bg-lightYellow rounded-2xl my-12 mx-2 px-2 max-w-4xl sm:mx-auto">
      {submitted && (
        <>
          <h2 className="text-center text-4xl sm:text-5xl mb-2 mt-0 ">
            Thanks for signing up!
          </h2>
          <p className="text-center text-gray-500">
            Check your email to confirm your email address and receive your
            masterclass!
          </p>
        </>
      )}
      {!submitted && (
        <>
          <div className="flex justify-center pb-3">
            <StaticImage
              src="../images/puppy-training-tips.jpg"
              alt="Puppy training"
              className="rounded-lg max-w-[60%]"
            />
          </div>
          <h2 className="text-center leading-1 text-4xl sm:text-4xl mb-2 mt-0 ">
            Unlock 10 Expert Puppy Training Tips!
          </h2>

          <p className="text-center text-gray-500 mb-6">
            Sign up with your email and receive a free video guide to transform
            your puppy into a well-behaved companion
          </p>
          <div className="flex  justify-center" id="sign-up-video-course">
            <form
              className="flex flex-col items-center justify-center"
              onSubmit={handleSignUp}
            >
              <div className="flex flex-col sm:flex-row w-full">
                <div className="mx-2 mb-4 sm:mb-8">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </div>

                <div className="mx-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="Email"
                  >
                    Email
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 mb-6 sm:mb-0 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    name="email"
                    placeholder="Email"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <button
                  className={`bg-primary hover:bg-lightPrimary rounded-md py-2 font-semibold px-6 text-white `}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Subscribing..." : "Sign me up!"}
                </button>
              </div>
              <span className="text-center text-gray-500 text-sm  mt-4">
                You&apos;re signing up to receive emails from Scott&apos;s
                School For Dogs
              </span>
            </form>
          </div>
        </>
      )}
    </div>
  )
}

export default PuppyEmailSignup
